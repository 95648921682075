import {Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {BaseComponent} from "./core/base.component";
import {Router} from "@angular/router";
import {ServiceBase} from "./core/service.base";
import {environment} from "../environments/environment";

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit {

  language = super.accessLanguage.length === 0 ? 'pt-BR' : super.accessLanguage;

  constructor(public router: Router,
              public translate: TranslateService,
              private serviceBase: ServiceBase
  ) {
    super(router, translate);
    translate.addLangs(['en-US', 'es-ES', 'pt-BR']);
    console.log('app component construtor');
    console.log(location.hostname);
    if (environment.isPartner.includes(location.hostname)) {
      console.log('parceiro');
      $('body').addClass('body-partner');
    } else {
      console.log('não é parceiro');
    }
    this.serviceBase.updatePartner(environment.isPartner.includes(location.hostname));
    // super.handlerCultureLanguage(this.language);
  }

  ngOnInit(): void {
    console.log('app component init');
    console.log(this.serviceBase.getPartner());
    setTimeout(() => {
      this.toggleLoader(false);
      // this.toggleLoaderCustom(false);
    }, 700);
  }

}
